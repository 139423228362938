.App {
  text-align: center;
}

.logo-img{
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: '10%';
  margin-bottom: '5%';
  width: '60%';
}
